<template>
  <v-footer
    tile
    class="font-weight-medium"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-container class="pt-0 pb-0" :fluid="fullWidthRoute.includes($route.name)" :class="{'text-center': fullWidthRoute.includes($route.name)}">
        <strong class="text--disabled">
          © {{ new Date().getFullYear() }} Positive Media Ltd || Terms and Legal
        </strong>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterLayout",
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  data() {
    return {
      fullWidthRoute: ['Producer', 'On Air', 'Staff', 'Tally', 'Sound Effect', 'SF Opportunity', 'Hourly Summary', 'Hourly Summary(Last 3hrs)', 'Hourly Report']
    }
  }
};
</script>
